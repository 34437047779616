import React from "react"
import SEO from "../components/seo"
import { graphql } from 'gatsby'

export const query = graphql`
	query($path: String!){
		pwPages(page_url: {eq: $path}) {
			lang
			seo{
				title
				description
				image {
					publicURL
				}
			}
		}
	}
`;

const IndexPage = (all) => {
	const pageData = all.data.pwPages
	const { path } = all
	const lang = all.pageContext.lang
	
	return(
		<>
			<SEO
				title={pageData.seo.title}
				description={pageData.seo.description}
				image={pageData.seo.image.publicURL}
				lang={lang}
				pageurl={path}
				// schemaOrg={schemaOrg}
			/>
			
		</>
	)
}

export default IndexPage
